/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.experience-commerce_layouts-navRow .section-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .experience-commerce_layouts-navRow .section-heading .headline {
    font-family: SofiaProBold, Arial, sans-serif;
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 2rem; }

.experience-commerce_layouts-navRow .slider-core-css.swiper-pagination-disabled {
  padding-bottom: 1.875rem; }
  .experience-commerce_layouts-navRow .slider-core-css.swiper-pagination-disabled .swiper-button-next,
  .experience-commerce_layouts-navRow .slider-core-css.swiper-pagination-disabled .swiper-button-prev,
  .experience-commerce_layouts-navRow .slider-core-css.swiper-pagination-disabled ~ .swiper-button-next,
  .experience-commerce_layouts-navRow .slider-core-css.swiper-pagination-disabled ~ .swiper-button-prev {
    top: calc(35% - 1.875rem); }

.experience-commerce_layouts-navRow .slider-core-css.swiper-scrollbar-disabled {
  padding-bottom: 2.125rem; }
  .experience-commerce_layouts-navRow .slider-core-css.swiper-scrollbar-disabled .swiper-button-next,
  .experience-commerce_layouts-navRow .slider-core-css.swiper-scrollbar-disabled .swiper-button-prev,
  .experience-commerce_layouts-navRow .slider-core-css.swiper-scrollbar-disabled ~ .swiper-button-next,
  .experience-commerce_layouts-navRow .slider-core-css.swiper-scrollbar-disabled ~ .swiper-button-prev {
    top: calc(30% - 2.125rem); }
